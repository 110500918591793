<template>
  <b-card no-body>
    <template #header>
      <h4 class="mr-auto">
        {{ cardTitle }}
      </h4>
      <!--      <b-button-->
      <!--        variant="primary"-->
      <!--        size="sm"-->
      <!--        @click="create"-->
      <!--      >-->
      <!--        Add New-->
      <!--      </b-button>-->
    </template>
    <b-row v-if="!!tableSettings">
      <b-col cols="6">
        <b-form-group
          class="my-2"
          label-size="sm"
        >
          <template v-if="tableSettings.refresh">
            <b-button
              size="sm"
              class="mr-3 ml-1"
              @click="initDataGrid(dataGridEndpoint)"
            >
              <i class="fa fa-sync" /> Refresh
            </b-button>
          </template>
          <template v-if="tableSettings.refresh">
            <b-form-select
              v-model="selectedPerPage"
              class="w-25"
              :options="perPageOptions"
              size="sm"
              @change="initDataGrid(dataGridEndpoint)"
            />
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          class="ml-2 mt-2 mb-2 mr-0"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
        >
          <template v-if="tableSettings.filter">
            <b-col>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="text"
                  placeholder="Type to filter"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </template>
          <template v-if="tableSettings.search">
            <b-col>
              <b-row>
                <b-col cols="4"><b-form-select
                  v-model="searchField"
                  :options="searchFieldOpts"
                  size="sm"
                /></b-col>
                <b-col>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="searchTerm"
                      type="text"
                      placeholder="Type to Search"
                    />

                    <b-input-group-append>
                      <b-button
                        v-if="searchTerm"
                        @click="initDataGrid()"
                      >Clear</b-button>
                      <b-button
                        :disabled="!searchTerm"
                        @click="searchGrid()"
                      >Search</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>

            </b-col>

          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :filter="filter"
      :no-local-sorting="true"
      striped
      bordered
      responsive
      show-empty
      hover
      :items="GridData"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="TableHeader"
      @sort-changed="sortingChanged"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
          <br>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(created_at)="row">
        <!--        {{row.item.created_at}}-->
        {{ transformDate(row.item.created_at, false, 'FF' ) }}
      </template>
      <template #cell(created_at)="row">
        <!--        {{row.item.created_at}}-->
        {{ transformDate(row.item.created_at, false, 'FF' ) }}

      </template>
      <template #cell(user_role_description)="row">
        <template v-if="row.item.user_role">
          {{ humanise(row.item.user_role.role.description) }}
        </template>

      </template>
      <template #cell(user_role_name)="row">
        <template v-if="row.item.user_role">
          {{ humanise(row.item.user_role.role.name) }}
        </template>

      </template>
      <template #cell(price)="row">
        <!--        {{row.item.created_at}}-->
        <vue-numeric
          read-only
          :precision="2"
          currency="MYR"
          separator=","
          :value="row.item.price"
        />
      </template>
      <template #cell(is_active)="row">
        <feather-icon
          v-if="!row.item.is_active"
          class="text-danger"
          size="16"
          icon="XIcon"
        />
        <feather-icon
          v-if="row.item.is_active"
          class="text-success"
          icon="CheckIcon"
          size="16"
        />

      </template>
      <template #cell(quantity)="row">
        <!--        {{row.item.created_at}}-->
        <vue-numeric
          read-only
          separator=","
          :value="row.item.quantity"
        />
      </template>
      <template #cell(actions)="row">
        <template v-if="TableActions.length > 0">
          <b-button-group size="sm">
            <template v-for="(btn,index) in TableActions">
              <template v-if="btn.action === 'view'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="viewRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>
              <template v-if="btn.action === 'edit'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="editRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>
              <template v-if="btn.action === 'remove'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="deleteRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>

            </template>
          </b-button-group>
        </template>

      </template>
    </b-table>

    <div class="row">
      <div
        v-if="!isBusy"
        class="col"
      >
        <label class="p-2">
          Showing {{ (currentPage - 1) * selectedPerPage + 1 }} to
          <!-- check if last page-->
          <template
            v-if="
              Math.ceil(tableSettings.rows / selectedPerPage) ===
                currentPage
            "
          >
            {{ GridSettings.recordsFiltered }}
          </template>
          <template v-if="currentPage === 1 && GridSettings.draw < selectedPerPage">
            {{ GridSettings.draw }}
          </template>
          <template v-if="currentPage !== 1 && GridSettings.recordsFiltered === selectedPerPage * currentPage">
            {{ selectedPerPage * currentPage }}
          </template>
          of
          <template
            v-if="
              GridSettings.recordsTotal !== GridSettings.recordsFiltered
            "
          >
            {{ GridSettings.recordsFiltered }}
            <template v-if="GridSettings.recordsFiltered > 1">entries</template>
            <template v-else>entry</template>
          </template>
          <template v-else>
            {{ GridSettings.recordsTotal }}

            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
          </template>
        </label>
        <template
          v-if="GridSettings.recordsTotal !== GridSettings.recordsFiltered"
        >
          <label>(filtered from {{ GridSettings.recordsTotal }} total
            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
            )</label>
        </template>
      </div>
      <div class="col">
        <b-pagination
          v-if="searchField === ''"
          v-model="currentPage"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />

        <b-pagination
          v-if="searchField !== ''"
          v-model="currentPage"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="GridSettings.recordsFiltered"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import Config from '@/views/administration/users/formConfig'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {
  },
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Users',
      dataGridEndpoint: [],
      module: 'users',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      isBusy: true,
      selectedPerPage: 50,
      sortDesc: true,

      record_status: 1, // active only
      searchField: 'name',
      sortBy: 'created_at',

      searchFieldOpts: [
        { value: 'name', text: 'Name' },
        { value: 'email', text: 'Email' },
      ],
    }
  },
  computed: {
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridData.length
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = this.tableConfig.tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = this.tableConfig.tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters[`${this.module}/activeUserList`]
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters[`${this.module}/getPaginationInfo`]

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Users' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

    this.isBusy = false
    this.initDataGrid()
  },
  methods: {
    editRow(itemId) {
      console.log('edit', itemId)
      this.$router.push({
        name: 'administration-users-form',
        params: { id: itemId, mode: 'edit' },
      })
    },
    deleteRow(itemId) {
      console.log('delete', itemId)
    },
    viewRow(itemId) {
      console.log('view', itemId)
      this.$router.push({
        name: 'administration-users-form',
        params: { id: itemId, mode: 'view' },
      })
    },
    create() {
      this.$router.push({
        name: 'administration-user-new',
      })
    },
    pagination(e) {
      if (this.tableSettings.search) {
        const payload = {
          start: (e - 1) * this.tableSettings.perPage,
          length: this.tableSettings.perPage,
          sort: this.sortObj,
          search: this.searchTerm,
        }
        this.isBusy = true
        this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
          this.isBusy = false
        })
      }
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(this.dataGridEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid(vuexEndpoint) {
      const payload = {
        start: 0,
        length: this.tableSettings.perPage,
        sort: this.sortObj,
        search: this.searchTerm,
      }
      this.isBusy = true
      this.$store.dispatch(vuexEndpoint, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid() {
      this.isBusy = true
      const payload = {
        start_date: '01-02-2000',
        end_date: '27-02-2029',
        record_status: 1,
        invoice_status: 'success',
        search: {
          search_enable: false,
          search_key: 'WEB_PAYMENT',
          search_column: 'payment_mode',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: 'desc',
        },
      }
      // const payload = {
      //   limit: this.tableSettings.perPage,
      //   skip: 0,
      //   search: {
      //     search_enable: false,
      //     search_key: '',
      //     search_column: '',
      //     search_replace_word_enable: true,
      //     search_word_replace: ' ',
      //     search_word_replace_to: '_',
      //   },
      //   sort: this.sortObj,
      // }
      this.isBusy = true
      this.$store.dispatch(`${this.module}/getUsers`, payload).then(() => {
        this.currentPage = 1
        this.isBusy = false

        this.searchTerm = ''
      })
    },
  },
}
</script>
