const Config = {
  formData() {
    return {
      title: {
        edit: 'Edit User',
        view: 'View User',
        remove: 'Remove User',
        create: 'Create New User',
      },
      form: [{
        show: {
          edit: true,
          remove: true,
          view: true,
          create: true,
        },
        formContext: 'user',
        formName: 'Create New User',
        elem: [
          {
            key: 'full_name',
            fieldName: 'Full Name',
            type: 'text',
            rules: 'required|alpha-spaces',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'email',
            fieldName: 'Email',
            type: 'email',
            rules: 'required|email:true',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'phone_number',
            fieldName: 'Phone Number',
            type: 'phone',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'password',
            fieldName: 'Password',
            type: 'password',
            rules: 'required',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'confirmPassword',
            fieldName: 'Confirm Password',
            type: 'password',
            rules: 'confirmed:password',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'is_active',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      },
      {
        formContext: 'roles',
        formName: 'User Roles',
        show: {
          edit: true,
          remove: true,
          view: false,
          create: false,
        },
        elem: [
          {
            key: 'user_role',
            fieldName: 'User Role',
            type: 'vselect',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            options: [
              {
                name: 'GUEST',
                description: 'A Guest Account',
                id: 1,
              },
              {
                name: 'ACCOUNT_ADMIN',
                description: 'Primary Administrator/Superuser For an Account',
                id: 2,
              },
              {
                name: 'ACCOUNT_MANAGER',
                description: 'Day to Day Administrator of Events For an Account',
                id: 3,
              },
              {
                name: 'ADMIN',
                description: 'Admin of Application Ecosystem',
                id: 4,
              },
              {
                name: 'SUPER_ADMIN',
                description: 'Super Administrator of Application Ecosystem',
                id: 5,
              },
              {
                name: 'MERCHANT',
                description: 'Super Administrator of Application Ecosystem',
                id: 6,
              },
              {
                name: 'MASTER_MERCHANT',
                description: 'Super Administrator of Application Ecosystem',
                id: 7,
              },
            ],
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          visible: true,
        },
        {
          key: 'full_name',
          label: 'Name',
          sortable: true,
          visible: true,
        },
        {
          key: 'phone_number',
          label: 'Phone',
          sortable: true,
          visible: false,
        },
        {
          key: 'user_role',
          label: 'User Role Object',
          sortable: true,
          visible: false,
        },
        {
          key: 'user_role_description',
          label: 'User Role Description',
          sortable: true,
          visible: false,
        },
        {
          key: 'user_role_name',
          label: 'User Role',
          sortable: true,
          visible: false,
        },
        {
          key: 'updated_at',
          label: 'Updated',
          sortable: true,
          visible: false,
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          visible: true,
        },
        {
          key: 'is_active',
          label: 'Active',
          sortable: true,
          visible: true,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
